@import '../../utilities/mixins';

.sign-in-form {
  display: flex;
  flex-direction: column;
  hr {
    margin: 7px 0;
    width: 100%;
  }
  .grecaptcha-badge {
    visibility: hidden;
    height: 0px !important;
  }
  .g-recaptcha {
    margin-bottom: 0 !important;
  }
  div:not([class]) {
    order: 1;
  }
  .forgot-password-link {
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

.rover-icon {
  vertical-align: middle;
}

.signin-icon {
  font-size: 24px;
  margin-right: 12px;
}

.sign-in-card-footer {
  font-size: 16px;
}

.rover-icon-google {
  height: 24px;
  width: 24px;
}

.rover-icon-apple {
  height: 22px;
  width: 22px;
  color: $white;
}

.sign-up-country-selector {
  width: 40%;
  padding: 5px;
  z-index: 1;
  background: $white;
  border: 0;
  color: $link-color;
  &:hover {
    cursor: pointer;
    color: $link-hover-color;
  }

  option {
    text-align: left;
    p {
      padding: 8px;
      margin: 0;
    }
  }
  &:focus {
    border: 1px solid $gray-2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

/* this is for hiding the increment/decrement arrows on the zip-code input */
/* Chrome, Safari, Edge, Opera */
input.zip-code::-webkit-outer-spin-button,
input.zip-code::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input.zip-code[type=number] {
  -moz-appearance: textfield;
}

.larger-checkbox {
  width: 24px;
  height: 24px;
}

select {
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid $gray-2;
}

input.larger-checkbox[type='checkbox'] {
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid $gray-2;
  padding: 7px 7px 7px 12px;
  background: white;
  outline-offset: 2px;
  border-radius: 4px;
}

input.larger-checkbox[type='checkbox']:checked {
  display: inline-block;
  background: white;
  border: 2px solid $brand-rover;
  border-radius: 4px;
}

input.larger-checkbox[type='checkbox']:checked::before {
  content: '';
  position: absolute;
  width: 3px;
  height: 12px;
  background-color: $brand-rover;
  left: 27px;
  top: 6px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

input.larger-checkbox[type='checkbox']:checked::after {
  content: '';
  position: absolute;
  width: 3px;
  height: 6px;
  background-color: $brand-rover;
  left: 23px;
  top: 11px;
  -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
  transform: rotate(-45deg);
}

.rover-authentication-modal .modal-dialog .modal-content {
  @include border-radius(4px);
  .close {
    font-size: 26px;
    position: absolute;
    right: -14px;
    top: -14px;
  }
  .js-alert-close {
    font-size: 21px;
    position: relative;
    right: -8px;
    top: -8px;
  }
  .authentication-modal {
    padding: 18px;
    .primary-sign-up {
      padding: 0 48px 8px 48px;
    }
    .rover-header-title {
      font-size: 32px;
      margin-bottom: 24px;
      margin-top: 0;
      padding: 0 48px;
    }
  }

  @include xs-only {
    padding: 16px;
    .close {
      right: -10px;
      top: -10px;
    }
    .authentication-modal {
      padding: 32px 0;
      .primary-sign-up {
        padding: 0;
      }
      .rover-header-title {
        font-size: 28px;
        padding: 0;
      }
    }
  }
}
